<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Collections" subtitle="Manage collections of games from the wider library for renting out.">

		<app-content-head-actions-item text="Create" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no collections found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Name" />
				<app-table-column text="Rules" align="center" border="left" />
				<app-table-column text="Games" align="center" />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.name" />
					<app-table-cell-text :text="item.count.rules" align="center" border="left" />
					<app-table-cell-text :text="item.count.games" align="center" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: 'auto 100px 100px',
			endpoint: 'library/collections',
			live: 'library/collections',
			edit: 'Library.Collections.Edit'
		}

	}

}

</script>

<style scoped>

</style>